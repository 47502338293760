<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import Dropdown from '@/Components/Dropdown.vue';
import { Link, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const page = usePage();
const user = computed(() => page.props.auth.user);
</script>

<template>
    <div class="navbar px-2">
        <div class="navbar-start">
            <template v-if="!user">
                <Dropdown
                    orientation-class="dropdown-right lg:hidden"
                    menu-icon="fas fa-bars pr-3"
                >
                    <template #menu>
                        <li>
                            <a :href="route('landing-page') + '#faq'">{{ $t('landing_page.navbar.faq') }}</a>
                        </li>
                        <li>
                            <span>{{ $t('landing_page.navbar.functions') }}</span>
                            <ul class="p-2">
                                <li>
                                    <a :href="route('features.messages')">
                                        {{ $t('landing_page.navbar.function_messages') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('features.treasures')">
                                        {{ $t('landing_page.navbar.function_treasures') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('landing-page') + '#documents'">
                                        {{ $t('landing_page.navbar.function_documents') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('landing-page') + '#socials'">
                                        {{ $t('landing_page.navbar.function_socials') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('landing-page') + '#ec'">
                                        {{ $t('landing_page.navbar.function_ec') }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a :href="route('contact.index')">
                                {{ $t('landing_page.navbar.contact') }}
                            </a>
                        </li>
                    </template>
                </Dropdown>
            </template>
            <Link :href="route('landing-page')" class="inline-block">
                <ApplicationLogo type="primary" />
            </Link>
        </div>
        <div class="navbar-center hidden lg:flex">
            <template v-if="!user">
                <ul class="menu menu-horizontal px-1">
                    <li>
                        <a :href="route('landing-page') + '#faq'">{{ $t('landing_page.navbar.faq') }}</a>
                    </li>
                    <li>
                        <details>
                            <summary>
                                {{ $t('landing_page.navbar.functions') }}
                            </summary>
                            <ul class="dropdown-content bg-white border menu rounded-2xl z-[1] w-64 p-2 shadow-none">
                                <li>
                                    <a :href="route('features.messages')">
                                        {{ $t('landing_page.navbar.function_messages') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('features.treasures')">
                                        {{ $t('landing_page.navbar.function_treasures') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('landing-page') + '#documents'">
                                        {{ $t('landing_page.navbar.function_documents') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('landing-page') + '#socials'">
                                        {{ $t('landing_page.navbar.function_socials') }}
                                    </a>
                                </li>
                                <li>
                                    <a :href="route('landing-page') + '#ec'">
                                        {{ $t('landing_page.navbar.function_ec') }}
                                    </a>
                                </li>
                            </ul>
                        </details>
                    </li>
                    <li>
                        <a :href="route('contact.index')">
                            {{ $t('landing_page.navbar.contact') }}
                        </a>
                    </li>
                </ul>
            </template>
        </div>
        <div class="navbar-end">
            <template v-if="!user">
                <Link
                    :href="route('register')"
                    class="btn btn-warning btn-sm me-2 hidden lg:flex"
                >
                    {{ $t('landing_page.navbar.action_register') }}
                </Link>
                <Link
                    :href="route('login')"
                    class="btn btn-warning btn-outline btn-sm"
                >
                    {{ $t('landing_page.navbar.action_login') }}
                </Link>
            </template>
            <template v-else>
                <Link
                    :href="route('dashboard')"
                    class="btn btn-warning btn-outline btn-sm"
                >
                    {{ $t('sidebar.my-afterlife') }}
                </Link>
            </template>
        </div>
    </div>
</template>

<style scoped>

</style>
