<script setup>

const props = defineProps({
   bgTypeClass: {
       type: String,
   }
});

</script>

<template>
    <div class="px-4" :class="props.bgTypeClass">
        <div class="mx-auto sm:max-w-7xl">
            <slot />
        </div>
    </div>
</template>

<style scoped>

</style>
